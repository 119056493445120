@import '../../../sass/variables';

.newsBlocks-carousel {
    background-color: $altBgGrey;
    overflow: hidden;
    padding: 30px 15px;

    .newsBlocks {
        border: 1px solid transparent; 
        transition: background-color 0.5s ease-in, border-color 0.3s ease-in;

        &:hover,
        &:focus {
            background-color: darken($proBlueLightAlt, 1%);
            border-color: $proBlue;
        }
        a {
            background: $white;
            color: $proBlueLight;
            display: block;
            height: 170px;
            overflow: hidden;
            padding: 20px;

            &:hover,
            &:focus {
                background-color: darken($proBlueLightAlt, 1%);
            }

            .blocksContent {
                .initial-text {
                    min-height: 50px;
                    margin: 5px 0;
                }
                .read-more {
                }
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            .blocksDate {
                color: $proVeryDarkBlue;
                font-family: $headerBoldFontStack;
                font-size: 16px;
            }

            .truncated-wrapper {
                font-size: 14px;
                line-height: 18px;
            }

            p {
                color: $proTextGray;

                &.readMore {
                    color: $linkColor;
                    margin-bottom: 0;
                }
            }
        }
    }

    .slick-slider {
        touch-action: auto;
        .slick-arrow {
            height: 60px;
            left: -55px;
            margin-top: -55px;
            opacity: 0.5;
            padding: 15px 5px;
            text-align: center;
            top: 50%;
            transition: opacity 0.5s ease;
            width: 35px;
            z-index: 1;

            &:hover,
            &:focus {
                opacity: 1;
            }

            &::before {
                padding: 15px 5px;
                color: $solBlue;
            }
        }

        .slick-dots {
            li {
                button {
                    border: 1px solid $proAccessibleGrey;
                }
            }
            .slick-active {
                button {
                    background-color: $proAccessibleGrey;
                }
            }
        }

        .slick-next {
            left: auto;
            right: -55px;
        }
    }
}

.lang-zh {
    .newsBlocks-carousel {
        .newsBlocks {
            a {
                .blocksDate {
                    font-family: $headerBoldFontStackCN;
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .newsBlocks-carousel {
        overflow: hidden;
        padding: 50px 0;

        .newsBlocksSlide {
            padding: 5px;
            width: 300px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .newsBlocks-carousel {
        .newsBlocks {
            a {
                height: auto;
            }
        }
    }
}